import React from 'react';

import { LanguageContext } from '../../context/LanguageContext';
import './Exchange.css';
import iconExachange from './images/exchange-icon.png';
import iconUsers from './images/user-icon.png';
import iconCoins from './images/coins-icon.png';
import iconMachine from './images/machine-icon.png';

class Exchange extends React.Component {

    static contextType = LanguageContext;

    render() {
        let exchangeData = this.context.exchange;

        return(
            <aside id="exchange-listing">
                <div className="container">
                    <div>
                        <img src={iconExachange} alt="Exchange"/>
                        <p>{exchangeData.label1.text}</p>
                    </div>
                    <div>
                        <img src={iconUsers} alt="Users"/>
                        <h3>{exchangeData.label2.title}</h3>
                        <p>{exchangeData.label2.text}</p>
                    </div>
                    <div>
                        <img src={iconCoins} alt="Coins"/>
                        <h3>{exchangeData.label3.title}</h3>
                        <p>{exchangeData.label3.text}</p>
                    </div>
                    <div>
                        <img src={iconMachine} alt="Payment machine"/>
                        <h3>{exchangeData.label4.title}</h3>
                        <p>{exchangeData.label4.text}</p>
                    </div>
                </div>
            </aside>
        );
    }
}

export default Exchange;